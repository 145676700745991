import { template as template_ca3bc85217da430686ff67029d95b811 } from "@ember/template-compiler";
const FKLabel = template_ca3bc85217da430686ff67029d95b811(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
