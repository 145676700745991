import { template as template_a79220f2a7ed431ebdd4bfc34027473d } from "@ember/template-compiler";
const SidebarSectionMessage = template_a79220f2a7ed431ebdd4bfc34027473d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
