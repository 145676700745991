import { template as template_07c21d44a2d04e6f87590bf4c7c787d8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_07c21d44a2d04e6f87590bf4c7c787d8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
