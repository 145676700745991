import { template as template_d012d375dd8a492dbd96cf2bd4e4b0e1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_d012d375dd8a492dbd96cf2bd4e4b0e1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
