import { template as template_37cbaf0d8abf4f5caf1b60e96830c07d } from "@ember/template-compiler";
const FKControlMenuContainer = template_37cbaf0d8abf4f5caf1b60e96830c07d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
